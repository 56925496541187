import React, { useState, useEffect } from 'react';
import './Notification.css'; // Use your existing CSS or create a new file for styling
import { useNavigate } from 'react-router-dom';
import { SoundWaveLoader } from './SoundWaveLoader';

export default function History() {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true); // State to manage loading
  const username = window.Telegram?.WebApp?.initDataUnsafe?.user?.username || String(window.Telegram?.WebApp?.initDataUnsafe?.user?.id);
  const navigate = useNavigate();
  
  const goBack = () => {
    navigate('/softie'); // Navigate back to the /softie page
  };

  useEffect(() => {
    if (!username) {
      console.error('Username not found');
      return;
    }

    // Fetch withdrawals and transactions and merge them
    const fetchTransactions = async () => {
      try {
        const withdrawalsResponse = await fetch(`https://softie.onrender.com/api/users/${username}/withdrawals`);
        const withdrawalsData = await withdrawalsResponse.json();

        const transactionsResponse = await fetch(`https://softie.onrender.com/api/users/${username}/transactions`);
        const transactionsData = await transactionsResponse.json();

        // Format the withdrawals and transactions for display
        const formattedWithdrawals = withdrawalsData.withdrawals.map(withdrawal => ({
          type: 'Withdrawal',
          amount: -withdrawal.amount,
          date: Date.parse(withdrawal.createdAt) ? new Date(withdrawal.createdAt) : 'Invalid Date',
          status: withdrawal.status,
          currency: withdrawal.currency,
          isWithdrawal: true,
        }));

        const formattedTransactions = transactionsData.map(transaction => ({
          type: 'Deposit',
          amount: transaction.amount,
          date: Date.parse(transaction.createdAt) ? new Date(transaction.createdAt) : 'Invalid Date',
          status: transaction.status,
          currency: transaction.cryptoType,
          isWithdrawal: false,
        }));

        // Combine and sort by date (descending)
        const combinedTransactions = [...formattedWithdrawals, ...formattedTransactions].sort(
          (a, b) => b.date - a.date
        );

        setTransactions(combinedTransactions);
      } catch (error) {
        console.error('Error fetching transaction history:', error);
      } finally {
        setLoading(false); // Set loading to false once data is fetched
      }
    };

    fetchTransactions();
  }, [username]);

  return (
    <div className="transaction-page">
      <div onClick={goBack}>
        <i className="fas fa-arrow-left" style={{ color: 'white', position: 'fixed', left: '20px', top: '40px', zIndex: '500', fontSize: '20px' }}></i>
      </div>
      <h2 style={{ marginTop: '18px', fontSize: '30px'}}>Transaction History</h2>

      {loading ? ( // Display "Loading..." while loading is true
        <div style={{ marginLeft: '0px' }}>
        <SoundWaveLoader /> 
      </div>
      ) : (
        <div className="transaction-list">
          {transactions.map((transaction, index) => (
            <TransactionItem key={index} transaction={transaction} />
          ))}
        </div>
      )}
    </div>
  );
}

function TransactionItem({ transaction }) {
  const [expanded, setExpanded] = useState(false);

  // Determine the styles based on the transaction status
  const getAmountStyle = () => {
    if (transaction.status === 'pending') {
      return { color: 'gray' };
    } else if (transaction.status === 'declined') {
      return { color: 'gray', textDecoration: 'line-through' };
    } else {
      return { color: transaction.isWithdrawal ? '#fd9e9e' : '#78ed78' };
    }
  };

  return (
    <div className="transaction-item" onClick={() => setExpanded(!expanded)}>
      <div className="transaction-summary">
        <span className="transaction-title">{transaction.type}</span>
        <span className="transaction-amount" style={getAmountStyle()}>
          {transaction.isWithdrawal ? '-' : '+'}${Math.abs(transaction.amount)}
        </span>
      </div>
      {expanded && (
        <div className="transaction-details">
          <p>Status: {transaction.status}</p>
          <p>Date: {transaction.date.toLocaleString()}</p>
          <p>Currency: {transaction.currency}</p>
        </div>
      )}
    </div>
  );
}
