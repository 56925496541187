import BottomNav from './BottomNav';
import React, { useState } from 'react';

export default function Miner() {
    const [activeItem, setActiveItem] = useState('miner'); 

    return (

        <div>
            <BottomNav activeItem={activeItem} setActiveItem={setActiveItem} />
        </div>

    );

}
