import BottomNav from './BottomNav';
import { useState, useEffect } from 'react';
import './Friends.css';
import { useNavigate } from 'react-router-dom';
import CustomAlert from './CustomAlert'; // Import CustomAlert
import { SoundWaveLoader } from './SoundWaveLoader';

export default function Friends() {
  const [activeItem, setActiveItem] = useState('friends');
  const [friendsData, setFriendsData] = useState({
    referrals: [],
    totalEarnings: 0,
  });
  const [totalSoftieBonus, setTotalSoftieBonus] = useState(0);
  const [alertMessage, setAlertMessage] = useState(null); // Manage alert state
  const navigate = useNavigate(); 
  const [loading, setLoading] = useState(true); // State to manage loading

  
  const closeAlert = () => {
    setAlertMessage(null); // Reset alertMessage to hide the alert
  };

  useEffect(() => {
    try {
      if (!window.Telegram || !window.Telegram.WebApp) {
        console.error('Telegram WebApp is not defined');
        return;
      }
  
      const webApp = window.Telegram.WebApp;
      webApp.ready();  // Ensure the app is fully loaded
  
      // Set the color of the title bar
      webApp.setHeaderColor('#020010');
  
    } catch (error) {
      console.error('An error occurred during initialization:', error);
    }
  }, []);  

  const contestPage = () => {
    navigate('/contest');
  };

  useEffect(() => {
    const fetchFriendsData = async () => {
        const telegramUser = window.Telegram?.WebApp?.initDataUnsafe?.user;
      
        // Use username if available, otherwise use chatId converted to a string
        const username = telegramUser?.username || String(telegramUser?.id);
  
        if (!username) {
          console.error('No Telegram user data found');
          return;
        }

      try {
        const response = await fetch(`https://softie.onrender.com/api/referrals/${username}`);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        setFriendsData(data);

        let softieBonus = 0;
        data.referrals.forEach(ref => {
          softieBonus += ref.commitmentBalance * 0.05;
        });
        setTotalSoftieBonus(softieBonus.toFixed(2));
      } catch (error) {
        console.error('Error fetching referrals:', error);
      } finally {
        setLoading(false); // Set loading to false once data is fetched
      }
    };

    fetchFriendsData();
  }, []);

const handleCopyLink = () => {
  const tg = window.Telegram.WebApp;
  const username = tg.initDataUnsafe?.user?.username || tg.initDataUnsafe?.user?.id;
  
  const referralLink = `https://t.me/SoftcoinAppBot?start=${username}`;
  
  // Copy referral link to clipboard
  navigator.clipboard.writeText(referralLink)
    .then(() => {
      showTelegramAlert('Referral link copied to clipboard!');
    })
    .catch(() => {
      showTelegramAlert('Failed to copy referral link.');
    });
};

const handleShareLink = () => {
  const tg = window.Telegram.WebApp;
  const username = tg.initDataUnsafe?.user?.username || tg.initDataUnsafe?.user?.id;
  
  const referralLink = `https://t.me/SoftcoinAppBot?start=${username}`;
  const shareMessage = `Join Softcoin and start earning $SFT & $USD! Use my referral link: ${referralLink}`;
  
  // Open Telegram Share Screen
  if (tg && tg.openTelegramLink) {
    const telegramShareLink = `https://t.me/share/url?url=${encodeURIComponent(referralLink)}&text=${encodeURIComponent(shareMessage)}`;
    tg.openTelegramLink(telegramShareLink);
  } else if (navigator.share) {
    // Fallback to Web Share API for external sharing
    navigator.share({
      title: 'Softcoin Referral',
      text: shareMessage,
      url: referralLink,
    })
      .then(() => console.log('Successfully shared'))
      .catch((error) => console.error('Error sharing:', error));
  } else {
    console.error('Sharing is not supported on this device');
  }
};



const showTelegramAlert = (message) => {
  const tg = window.Telegram.WebApp; // Access Telegram WebApp object
  if (tg && tg.showAlert) {
    tg.showAlert(message); // Use Telegram's showAlert method
  } else {
    console.error('Telegram WebApp is not available or showAlert is not supported');
  }
};

const showCustomAlert = (message) => {
    setAlertMessage(message); // Set the custom alert message to trigger the alert
};
  return (
    <div className="app">
      <h1 style={{ marginTop: '25px', fontSize: '30px'}}>Friends</h1>
    {loading ? ( // Display "Loading" while loading is true
        <div style={{ height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <SoundWaveLoader /> 
        </div>
      ) : (
  <div className="friends-page">
      
      <div id="shalaye">
        <p>Invite your friends and get <span style={{ color: 'white'}}>50,000 SFT</span> and <span style={{ color: 'white'}}>20 Spin Tickets</span>. You will also get <span style={{ color: 'white'}}>20%</span> of their mining rewards, <span style={{ color: 'white'}}>5%</span> of their commitment deposit, and <span style={{ color: 'white'}}>10%</span> of their daily return.</p>
        <img className="image" src="/adverts/friends.png" alt="People holding hands" />
      </div>
      <div style={{ width: '80%', margin: '10px auto', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <button style={{ width: '48%', background: 'gray', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center'}} className="friends-button" onClick={handleCopyLink}>
           Copy Link
         <i style={{ color: 'white', fontSize: '15px', marginLeft: '5px' }} className="fas fa-copy"></i>
        </button>
        <button style={{ width: '48%', display: 'flex', alignItems: 'center', justifyContent: 'center' }} className="friends-button" onClick={handleShareLink} >
           Share Link <i style={{ color: '#020010', fontSize: '15px', marginLeft: '5px' }} className="fas fa-share"></i>
       </button>
     </div>

      <div onClick={contestPage} className="submit-button leader-button" style={{ textAlign: 'center', width: '80%', marginTop: '20px', padding: '10px', margin: '20px auto', textDecoration: 'none'}}>
          <div>Monthly Referral Contest</div>
        <i className="fas fa-chevron-right" style={{ color: 'white', fontSize: '15px' }}></i>
      </div>
      <p id="earning-text" style={{ color: '#ffffff', fontSize: '12px', marginBottom: '0', backgroundColor: '#d7edff1a', width: '70%', borderRadius: '5px', margin: 'auto', marginTop: '5px', fontWeight: 'bold' }}>Referral Earnings</p>
      <div className="referral" style={{ borderBottom: 'solid 0.5px #c0c0c06a', paddingBottom: '10px' }}>
        <div>
          <p>Mining Bonus</p>
          <h3 className="earnings">{friendsData.totalEarnings} SFT</h3>
        </div>
        <div>
          <p>Softie Bonus</p>
          <h3 className="softie-bonus">{totalSoftieBonus} USD</h3>
        </div>
      </div>
      <div className="count">
        <h2 id="friends-count" style={{ marginTop: '5px', color: 'gold' }}>Your Friends:</h2><span style={{ color: 'gold' }}>{friendsData.referrals.length}</span>
      </div>
      <div className="friends-container">
        {friendsData.referrals.length === 0 ? (
          <p style={{ fontSize: '16px', marginTop: '20px'}}>You have not invited any friend yet. Click the buttons above to copy and share your invitation link.</p>
        ) : (
          friendsData.referrals.map((ref, index) => (
            <div key={index} className="friend-item">
              <h3>{ref.username}</h3>
              <div className="balances">
                <span>{ref.coinBalance} SFT</span><br />
                <span>{ref.commitmentBalance} USD</span>
              </div>
            </div>
          ))
        )}
      </div>
      
      {/* Custom Alert */}
    {alertMessage && <CustomAlert message={alertMessage} onClose={closeAlert} />}
      
    </div>
      )}
     <BottomNav activeItem={activeItem} setActiveItem={setActiveItem} />
    </div>
     
  );
}


