import React from "react";
import "./Modal.css";

const Modal = ({ isOpen, onClose, imageSrc, buttonText, onButtonClick }) => {
  if (!isOpen) return null; // Only render the modal if it's open

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div
        className="modal-content"
        onClick={(e) => e.stopPropagation()} // Prevent click event propagation
      >
        <button className="modal-close" onClick={onClose}>
          &times;
        </button>
        {imageSrc && <img src={imageSrc} alt="Modal Visual" className="modal-image" />}
        <div className="modal-buttons">
          <button className="modal-button" onClick={onButtonClick}>
            {buttonText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
