import { useState, useEffect } from 'react';
import './Softie.css'; 
import { useNavigate } from 'react-router-dom';
import { SoundWaveLoader } from './SoundWaveLoader';

export default function SoftieLeaders() {

  const [username] = useState(() => {
  const telegramUser = window.Telegram?.WebApp?.initDataUnsafe?.user;
  // Use username if available, otherwise fall back to chatId converted to a string
  return telegramUser?.username || String(telegramUser?.id) || '';
});
  const [earningBalance, setEarningBalance] = useState(0);
  const [commitmentBalance, setCommitmentBalance] = useState(null);
  const [dailyProfit, setDailyProfit] = useState(0);
  const [softieLevel, setSoftieLevel] = useState('');
  const [leaders, setLeaders] = useState([]);
  const [userRank, setUserRank] = useState('--');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  
  const goBack = () => {
    navigate('/softie'); // Navigate back to the /softie page
  };

  useEffect(() => {
  try {
    if (!window.Telegram || !window.Telegram.WebApp) {
      console.error('Telegram WebApp is not defined');
      return;
    }

    const webApp = window.Telegram.WebApp;
    webApp.ready();  // Ensure the app is fully loaded

    // Set the color of the title bar
    webApp.setHeaderColor('#020010');

  } catch (error) {
    console.error('An error occurred during initialization:', error);
  }
}, []);

  // Fetch leaderboard data
  useEffect(() => {
    async function loadLeaderboard() {
      try {
        const response = await fetch(`https://softie.onrender.com/api/leaderboard/${username}`);
        const data = await response.json();
        setLeaders(data.leaders);
        setUserRank(data.userRank || '--');
      } catch (error) {
        console.error('Error loading leaderboard:', error);
      } finally {
        setLoading(false); // Set loading to false once data is fetched
      }
    }

    if (username) {
      loadLeaderboard();
    }
  }, [username]);

  return (

         <div className="leaders-page contest-page">
        <div onClick={goBack}>
        <i className="fas fa-arrow-left" style={{ color: 'white', position: 'fixed', left: '20px', top: '40px', zIndex: '500', fontSize: '20px' }}></i>
        </div>
        {loading ? ( // Display "Loading" while loading is true
        <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <SoundWaveLoader /> 
        </div>
      ) : (
          <>
        <div className="leaderboard">
          <h2>Softie Leaderboard</h2>
          <div className="user-rank">
            <p>Your Rank: <span>{userRank}</span></p>
          </div>
          <h3>Top 10</h3>
          <div style={{ width: '94%'}} className="leaderboard-section">
            <ul className="leaderboard-list">
              {leaders.map((leader, index) => (
                <li key={leader.username} className="leaderboard-item">
                  
                  <div className="details">
                    <span className="username">{leader.username}</span>
                   <div className="money">
                    <span className="commitment"><img src="/icons/invest.png" alt="Money"/> ${leader.commitmentBalance}</span>
                    <span className="cash-out"><img src="/menu/softie-neon.png" alt="Money" /> ${leader.totalWithdrawal}</span>
                   </div>
                 </div>
                  <div className="rank-indicator">
                    {index === 0 && <img src="game/first.png" alt="1st rank" className="rank-icon" />}
                    {index === 1 && <img src="game/second.png" alt="2nd rank" className="rank-icon" />}
                    {index === 2 && <img src="game/third.png" alt="3rd rank" className="rank-icon" />}
                    {index > 2 && <span>{index + 1}</span>}
                  </div>
                </li>
              ))}
            </ul>
              </div>
        </div>
              </>
              )}
     </div>
    
  );
}
