
import BottomNav from './BottomNav';
import { useState, useEffect, useMemo, useCallback } from 'react';
import './Login.css'; 
import { useNavigate } from 'react-router-dom';

export default function More() {
  const [activeItem, setActiveItem] = useState('more');
  const navigate = useNavigate();
  
  const contestPage = () => {
    navigate('/contest'); // Navigate back to the /softie page
  };
  
  const cryptoMarketPage = () => {
    navigate('/crypto-market'); // Navigate back to the /softie page
  };
  
  const wheelOfFortunePage = () => {
    navigate('/wheel-of-fortune'); // Navigate back to the /softie page
  };
  useEffect(() => {
  try {
    if (!window.Telegram || !window.Telegram.WebApp) {
      console.error('Telegram WebApp is not defined');
      return;
    }

    const webApp = window.Telegram.WebApp;
    webApp.ready();  // Ensure the app is fully loaded

    // Set the color of the title bar
    webApp.setHeaderColor('#020010');

  } catch (error) {
    console.error('An error occurred during initialization:', error);
  }
}, []);


  return (
    <div className="more app">
      <h1 style={{ marginTop: '25px', fontSize: '30px'}}>More</h1>
      <div className="more-body">
        <a onClick={contestPage}><img src="new/battle.png" alt="banner"/></a>
        
        <a onClick={cryptoMarketPage} alt="banner"><img src="new/cryptomarket.png"/></a>
        <a onClick={wheelOfFortunePage} alt="banner"><img src="new/wheeloffortune.png"/></a>
      </div>
      <BottomNav activeItem={activeItem} setActiveItem={setActiveItem} />
    </div>
  );
}
