// taskDetails.js
export const taskDetails = {
    "daily-goat": { name: "Join Goat", target: 1, reward: 10000, action: "Join", logo: "tasklogo/goat.png" },
    "daily-paws": { name: "Start Farming PAWS", target: 1, reward: 10000, action: "Start", logo: "tasks/paws.png" },
    "daily-join-btn": { name: "Join Softcoin Telegram Channel", target: 1, reward: 10000, action: "Join", logo: "tasks/join.png" },
    "daily-time-farm": { name: "Start Farming Time", target: 1, reward: 10000, action: "Start", logo: "tasks/time.png" },
    "daily-earnbitmoon": { name: "Join EarnBitMoon", target: 1, reward: 10000, action: "Join", logo: "tasks/earnbitmoon.png" },
    "followw-btn": { name: "Follow Us On Twitter", target: 1, reward: 10000, action: "Follow", logo: "tasks/follow.png" },
    "memefix": { name: "Join MemeFi", target: 1, reward: 10000, action: "Join", logo: "tasklogo/memefi.png" },
    "join-btn": { name: "Join Softcoin Telegram Channel", target: 1, reward: 10000, action: "Join", logo: "tasks/join.png" },
    "miniapps": { name: "Try Mini Apps Center", target: 1, reward: 10000, action: "Try", logo: "tasklogo/miniapps.png" },
    "paws": { name: "Start Farming PAWS", target: 1, reward: 10000, action: "Start", logo: "tasks/paws.png" },
    "pi": { name: "Join The Pi Network", target: 1, reward: 10000, action: "Join", logo: "tasklogo/pi.png" },
    "mrbitgenie": { name: "Join BitGenie", target: 1, reward: 10000, action: "Join", logo: "tasklogo/bitgenie.png" },
    "refer2-btn": { name: "Invite 2 friends", target: 2, reward: 30000, logo: "tasks/refer2.png" },
    "refer5-btn": { name: "Invite 5 friends", target: 5, reward: 50000, logo: "tasks/refer5.png" },
    "refer10-btn": { name: "Invite 10 friends", target: 10, reward: 100000, logo: "tasks/refer10.png" },
    "mine2-btn": { name: "Complete 2 mining sessions", target: 2, reward: 10000, logo: "tasks/mine2.png" },
    "mine20-btn": { name: "Complete 20 mining sessions", target: 20, reward: 100000, logo: "tasks/mine20.png" },
    "mine100-btn": { name: "Complete 100 mining sessions", target: 100, reward: 1000000, logo: "tasks/mine100.png" },
    "farm-btn": { name: "Farm $TOMATO", target: 1, reward: 10000, action: "Farm", logo: "tasks/tomato.png" },
     "notpixel": { name: "Start Mining NOTPixel", target: 1, reward: 10000, action: "Start", logo: "tasklogo/notpixel.png" },
     "binance": { name: "Start Trading On Binance", target: 1, reward: 10000, action: "Start", logo: "tasklogo/binance.png" },
     "faucetpay": { name: "Earn Free Coins On FaucetPay", target: 1, reward: 10000, action: "Start", logo: "tasklogo/faucetpay.png" },
     "doctorx": { name: "Join DoctorX", target: 1, reward: 10000, action: "Join", logo: "tasklogo/doctorx.png" },
     "tondao": { name: "Join TONxDAO", target: 1, reward: 10000, action: "Join", logo: "tasklogo/tondao.png" },
     "coub": { name: "Start Mining COUB", target: 1, reward: 10000, action: "Start", logo: "tasklogo/coup.png" },
     "tada": { name: "Join Tada!", target: 1, reward: 10000, action: "Join", logo: "tasklogo/tada.png" },
    "wallet": { name: "Join Gra Wallet", target: 1, reward: 10000, action: "Join", logo: "tasklogo/grawallet.png" },
    "goat": { name: "Join Goat", target: 1, reward: 10000, action: "Join", logo: "tasklogo/goat.png" },
    "hamster": { name: "Become a CEO on HamsterKombat", target: 1, reward: 10000, action: "Go", logo: "tasklogo/hamster.png" },
    "sealsend": { name: "Join SealSend", target: 1, reward: 10000, action: "Start", logo: "tasklogo/sealsend.png" },
    "memelab": { name: "Start Earning on MemeLab", target: 1, reward: 10000, action: "Start", logo: "tasklogo/memelab.png" },
    "litecoin": { name: "Start Earning Free Litecoins", target: 1, reward: 10000, action: "Start", logo: "tasklogo/litecoin.png" },
    "earnbitmoon": { name: "Join EarnBitMoon", target: 1, reward: 10000, action: "Join", logo: "tasks/earnbitmoon.png" },
    "cats": { name: "Join the CatsGang", target: 1, reward: 10000, action: "Join", logo: "tasks/catsgang.png" },
    "sauce": { name: "Get some Sauce", target: 1, reward: 10000, action: "Go", logo: "tasks/sauces.png" },
    "satoshi": { name: "Join Satoshi", target: 1, reward: 10000, action: "Join", logo: "tasks/satoshi.png" },
    "truecoin": { name: "Start Mining Truecoin", target: 1, reward: 10000, action: "Start", logo: "tasks/truecoin.png" },
    "athene": { name: "Start Mining Athene", target: 1, reward: 10000, action: "Start", logo: "tasks/athene.png" },
    "simple": { name: "Join Simple Tap", target: 1, reward: 10000, action: "Join", logo: "tasks/simpletap.png" },
    "cex": { name: "Start Mining CEX", target: 1, reward: 10000, action: "Start", logo: "tasks/cex.png" },
    "match-quest": { name: "Join MatchQuest", target: 1, reward: 10000, action: "Join", logo: "tasks/matchquest.png" },
    "blum": { name: "Start Farming BLUM", target: 1, reward: 10000, action: "Start", logo: "tasks/blum.png" },
    "seed-btn": { name: "Start farming Seeds", target: 1, reward: 10000, action: "Start", logo: "tasks/seed.png" },
    "sunwave": { name: "Join SUNWAVES", target: 1, reward: 10000, action: "Go", logo: "tasks/sunwave.png" },
    "call": { name: "Join CALL", target: 1, reward: 10000, action: "Join", logo: "tasks/call.png" },
    "lost-dog": { name: "Join the Lost Dogs", target: 1, reward: 10000, action: "Join", logo: "tasks/lostdogs.png" },
    "major": { name: "Become A Major", target: 1, reward: 10000, action: "Go", logo: "tasks/major.png" },
    "money-dog": { name: "Start Farming Money-Dog", target: 1, reward: 10000, action: "Start", logo: "tasks/moneydog.png" },
    "mozoai": { name: "Join MozoAI", target: 1, reward: 10000, action: "Join", logo: "tasks/mozoai.png" },
    "nomiz": { name: "Start Mining Nomis", target: 1, reward: 10000, action: "Start", logo: "tasks/nomis.png" },
    "time-farm": { name: "Start Farming Time", target: 1, reward: 10000, action: "Start", logo: "tasks/time.png" },
    "vertus": { name: "Join Vertus", target: 1, reward: 10000, action: "Join", logo: "tasks/vertus.png" },
    "hot": { name: "Start Farming HOT", target: 1, reward: 10000, action: "Start", logo: "tasks/hot.png" },
    "wonton": { name: "Earn some $WONTON", target: 1, reward: 10000, action: "Earn", logo: "tasks/wonton.png" },
    "sidefan": { name: "Join SideFan", target: 1, reward: 10000, action: "Join", logo: "tasks/sidefan.png" },
    "agent": { name: "Join Agent301", target: 1, reward: 10000, action: "Join", logo: "tasks/agent301.png" },
     "pocket": { name: "Join PocketFi", target: 1, reward: 10000, action: "Join", logo: "tasks/pocketfi.png" },
     "freebitcoin": { name: "Start Earning Free Bitcoin", target: 1, reward: 10000, action: "Start", logo: "tasks/freebitcoin.png" },
     "coinpayu": { name: "Start Earning On Coinpayu", target: 1, reward: 10000, action: "Start", logo: "tasks/coinpayu.png" },
  };
  
