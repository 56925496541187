import { useState } from 'react';

export const useGetInvoiceLink = () => {
  const [loading, setLoading] = useState(false);

  const fetchInvoiceLink = async (level, stars, username) => {
    setLoading(true);
    try {
      const response = await fetch('https://softcoin-bot.onrender.com/create-invoice', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ level, stars, username }),
      });

      if (!response.ok) {
        throw new Error('Failed to create invoice');
      }

      const data = await response.json();
      return data.invoiceLink;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return { fetchInvoiceLink, loading };

};

