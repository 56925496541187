import BottomNav from './BottomNav';
import { useState, useEffect } from 'react';
import './Softie.css'; 
import { useNavigate } from 'react-router-dom';
import { SoundWaveLoader } from './SoundWaveLoader';

export default function Softie() {
  const [activeItem, setActiveItem] = useState('softie');
  const [username] = useState(() => {
  const telegramUser = window.Telegram?.WebApp?.initDataUnsafe?.user;
  // Use username if available, otherwise fall back to chatId converted to a string
  return telegramUser?.username || String(telegramUser?.id) || '';
});
  const [earningBalance, setEarningBalance] = useState(0);
  const [commitmentBalance, setCommitmentBalance] = useState(null);
  const [dailyProfit, setDailyProfit] = useState(0);
  const [softieLevel, setSoftieLevel] = useState('');
  const [leaders, setLeaders] = useState([]);
  const [userRank, setUserRank] = useState('--');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true); // State to manage loading

  
  const depositPage = () => {
    navigate('/deposit');
  };

  const leadersPage = () => {
    navigate('/softie-leaders');
  };
  
  const withdrawPage = () => {
    navigate('/withdraw');
  };
  
  const softieLevelsPage = () => {
    navigate('/softie-levels');
  };

useEffect(() => {
  try {
    if (!window.Telegram || !window.Telegram.WebApp) {
      console.error('Telegram WebApp is not defined');
      return;
    }

    const webApp = window.Telegram.WebApp;
    webApp.ready();  // Ensure the app is fully loaded

    // Set the color of the title bar
    webApp.setHeaderColor('#020010');

  } catch (error) {
    console.error('An error occurred during initialization:', error);
  }
}, []);

  // Fetch earning balance and commitment balance
  useEffect(() => {
    const fetchEarningBalance = async () => {
      try {
        const response = await fetch(`https://softie.onrender.com/api/users/${username}/earningBalance`);
        if (!response.ok) {
          throw new Error('Failed to fetch earning balance');
        }
        const data = await response.json();
        setEarningBalance(data.earningBalance.toFixed(2));
      } catch (error) {
        console.error('Error fetching earning balance:', error);
      }
    };

    const fetchCommitmentBalance = async () => {
      try {
        const response = await fetch(`https://softie.onrender.com/api/users/${username}/commitmentBalance`);
        const data = await response.json();
        if (data.error) {
          
        } else {
          const balance = data.commitmentBalance;
          setCommitmentBalance(balance);

          let profit = 0;
          let level = '';

          if (balance >= 5 && balance <= 30) {
            profit = balance * 0.015;
            level = 'Rising Softie';
          } else if (balance >= 31 && balance <= 100) {
            profit = balance * 0.02;
            level = 'Junior Softie';
          } else if (balance >= 101 && balance <= 500) {
            profit = balance * 0.025;
            level = 'Pro Softie';
          } else if (balance >= 501 && balance <= 2000) {
            profit = balance * 0.03;
            level = 'Expert Softie';
          } else if (balance >= 2001 && balance <= 5000) {
            profit = balance * 0.035;
            level = 'Master Softie';
          } else if (balance >= 5001) {
            profit = balance * 0.04;
            level = 'Legend Softie';
          }

          setDailyProfit(profit.toFixed(2));
          setSoftieLevel(level);
        }
      } catch (error) {
        console.error('Error fetching commitment balance:', error);
      } finally {
        setLoading(false); // Set loading to false once data is fetched
      }
    };

    if (username) {
      fetchEarningBalance();
      fetchCommitmentBalance();
    }
  }, [username]);

  // Start the countdown on component mount
  useEffect(() => {
    if (commitmentBalance > 0) {
      const startCountdown = () => {
        const countdownElement = document.getElementById('countdown');

        function updateCountdown() {
          const now = new Date();
          const gmtMidnight = new Date();
          gmtMidnight.setUTCHours(24, 0, 0, 0); // Set to 12 AM GMT

          const timeRemaining = gmtMidnight - now;
          const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

          countdownElement.textContent = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

          if (timeRemaining > 0) {
            setTimeout(updateCountdown, 1000);
          } else {
            window.location.reload();
          }
        }

        updateCountdown();
      };

      startCountdown();
    }
  }, [commitmentBalance]);

  return (
    <div className="main-page">
    {loading ? ( // Display "Loading" while loading is true
        <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <SoundWaveLoader /> 
        </div>
      ) : (
      <>
       <h1 style={{ paddingTop: '25px', fontSize: '30px'}}>Softie</h1>
       <div className="solo">
      <div className="income">
        <div className="income-text over">Your Daily Income:</div>
        <div className="income-figure"><div className="decor two">${dailyProfit}</div><div className="decor">{earningBalance} USD</div></div>
        
          <div className="income-text under">
            <span style={{ opacity: '0.1'}}>|</span>
            {commitmentBalance > 0 && (
            <div style={{marginLeft: '-40px', fontSize: '10px'}} className="countdown" id="countdown-timer">
              Your Next Income Will Drop in <span id="countdown" style={{color: 'gold'}}>00:00:00</span>
            </div>
            )}
            <span style={{ background: '#d7edff1a', borderRadius: '5px'}} className="your">Your Balance</span>
          </div>
        </div>
      </div>
      <div className="softie-bodys">
        <div className="commit">
          <div><p>COMMITMENT: <span>{commitmentBalance} USD </span></p></div>
          <div className="softie-level">{softieLevel}</div>
        </div>
        <div className="mymenu">
          <div className="tiles">
            <div onClick={depositPage} className="tile">
              <img src="softies/deposit.png" alt="Deposit"/>
              <p>Deposit</p>
            </div>
            <div onClick={withdrawPage} className="tile">
              <img src="softies/cash-out.png" alt="Cash Out"/>
              <p>Cash Out</p>
            </div>
            <a href="https://softcoin.world/softie" className="tile" target="_blank" rel="noopener noreferrer">
              <img src="softies/whats.png" alt="What's Softie?" />
              <p>Softie?</p>
            </a>
            <div onClick={softieLevelsPage} className="tile">
              <img src="softies/levels.png" alt="Levels" />
              <p>Levels</p>
            </div>
          </div>
          <div onClick={leadersPage} className="submit-button leader-button" style={{ textAlign: 'center', width: '100%', marginTop: '30px', padding: '10px', margin: '30px auto', textDecoration: 'none'}}>
          <div>Leaderboard</div>
        <i className="fas fa-chevron-right" style={{ color: 'white', fontSize: '15px' }}></i>
          </div>
          <div onClick={depositPage} className="submit-button" style={{ textAlign: 'center', width: '100%', marginTop: '30px', padding: '10px', margin: '30px auto', textDecoration: 'none'}}>
            Make A Commitment
          </div>
        </div>
        
      </div>
              </>
              )}
      <BottomNav activeItem={activeItem} setActiveItem={setActiveItem} />
    </div> 
  );
}
