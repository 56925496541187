import React from 'react';
import './SoundWaveLoader.css'; // Create a new CSS file for the animation styles

export function SoundWaveLoader() {
  return (
    <div className="loaderContainer">
      <div className="loader"></div>
    </div>
  );
}
