import { useState, useEffect } from 'react';
import './Upgrade.css';
import WebApp from '@twa-dev/sdk';
import CustomAlert from './CustomAlert';
import { useNavigate } from 'react-router-dom';
import { SoundWaveLoader } from './SoundWaveLoader';
import { useGetInvoiceLink } from './GetInvoiceLink';
 

export default function Upgrade() {
  const [currentLevel, setCurrentLevel] = useState(null);
  const [username, setUsername] = useState('');
  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertCallback, setAlertCallback] = useState(null);
  const { fetchInvoiceLink, loading: invoiceLoading } = useGetInvoiceLink();


  const closeAlert = () => {
    setAlertMessage(null);
    if (alertCallback) {
      alertCallback();
      setAlertCallback(null);
    }
  };

 const showTelegramAlert = (message, callback = null) => {
  const tg = window.Telegram.WebApp; // Access Telegram WebApp object
  if (tg && tg.showAlert) {
    tg.showAlert(message, () => {
      if (callback) callback();
    }); // Use Telegram's showAlert method with a callback
  } else {
    console.error('Telegram WebApp is not available or showAlert is not supported');
  }
};


  const navigate = useNavigate();

  const goBack = () => {
    navigate('/home');
  };

  useEffect(() => {
    try {
      if (!window.Telegram || !window.Telegram.WebApp) {
        console.error('Telegram WebApp is not defined');
        return;
      }

      const webApp = window.Telegram.WebApp;
      webApp.ready();
      webApp.setHeaderColor('#020010');
    } catch (error) {
      console.error('An error occurred during initialization:', error);
    }
  }, []);

  useEffect(() => {
  const fetchMiningStatus = async (username) => {
    try {
      const response = await fetch('https://softie.onrender.com/api/miningStatus', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username }),
      });
      const data = await response.json();
      if (data && data.level) {
        setCurrentLevel(data.level);
        setLoading(false); // Stop loading once level is fetched
      } else {
        console.error('Mining status response missing level data');
      }
    } catch (error) {
      console.error('Error fetching mining status:', error);
      showTelegramAlert('Failed to fetch user data');
      setLoading(false); // Stop loading even if the fetch fails
    }
  };

  const telegram = window.Telegram?.WebApp;
  if (telegram?.initDataUnsafe?.user?.username) {
    const username = telegram.initDataUnsafe.user.username;
    setUsername(username);
    fetchMiningStatus(username);
  } else if (telegram?.initDataUnsafe?.user?.id) {
    const username = telegram.initDataUnsafe.user.id.toString();
    setUsername(username);
    fetchMiningStatus(username);
  } else {
    console.error('No username or user ID found in Telegram WebApp data');
    setLoading(false); // Stop loading if username cannot be set
  }
}, []);


useEffect(() => {
  const stopLoaderAfterRender = () => {
    // Check if the current level badge has been updated in the UI
    const currentLevelBadge = document.querySelector(`.level-option[data-level="${currentLevel}"] .badge`);
    if (currentLevelBadge) {
      setLoading(false); // Stop loading when the badge is rendered
    }
  };

  if (currentLevel !== null) {
    // Wait for the DOM to render and then check for the badge
    const timeout = setTimeout(stopLoaderAfterRender, 200); // Adjust timing as needed
    return () => clearTimeout(timeout);
  }
}, [currentLevel]);

  const levels = [
    { level: 1, reward: '2,500', session: '2 Hours', cost: 'Default Level', stars: 0 },
    { level: 2, reward: '3,300', session: '3 Hours', cost: '200 Stars', stars: 200 },
    { level: 3, reward: '5,000', session: '4 Hours', cost: '500 Stars', stars: 500 },
    { level: 4, reward: '8,000', session: '5 Hours', cost: '1000 Stars', stars: 1000 },
    { level: 5, reward: '13,300', session: '6 Hours', cost: '2000 Stars', stars: 2000 },
  ];
  
  const triggerInvoice = async (level, stars) => {
  if (!window.Telegram || !window.Telegram.WebApp) {
    showTelegramAlert('Telegram WebApp is not available');
    return;
  }

  const telegram = window.Telegram.WebApp;
  telegram.ready();

  try {
    const invoiceLink = await fetchInvoiceLink(level, stars, username);

    WebApp.openInvoice(invoiceLink, async (status) => {
      if (status === 'paid') {
        try {
          const response = await fetch('https://softcoin-bot.onrender.com/api/upgradeLevel', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ username, level }),
          });

          const data = await response.json();
          if (data.success) {
            showTelegramAlert(`Successfully upgraded to level ${level}!`, () => {
              setCurrentLevel(level);
              navigate('/home'); // Redirect after alert is closed
            });
          } else {
            showTelegramAlert(data.message);
          }
        } catch (error) {
          showTelegramAlert('Failed to finalize the upgrade');
        }
      } else {
        showTelegramAlert('Payment was not completed');
      }
    });
  } catch (error) {
    showTelegramAlert('Failed to fetch the invoice link');
  }
};


  const showCustomAlert = (message, callback = null) => {
    setAlertMessage(message);
    if (callback) {
      setAlertCallback(() => callback);
    }
  };

 useEffect(() => {
  if (currentLevel !== null) {
    const targetLevel = currentLevel === 5 ? currentLevel : currentLevel + 1;
    const targetElement = document.querySelector(`.level-option[data-level="${targetLevel}"]`);
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }
}, [currentLevel]);

  return (
    <div className="Upgrade app">
      <div onClick={goBack}>
        <i className="fas fa-arrow-left" style={{ color: 'white', position: 'fixed', left: '18px', top: '40px', zIndex: '500', fontSize: '20px' }}></i>
  </div>
    {loading ? (
  <div
    style={{
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <SoundWaveLoader />
  </div>
) : (
  <>
    <div id="upgrade-options">
      <p id="text">
        Unlock bigger rewards and longer sessions with every upgrade. The goal is to make it to the top, and become the
        king you are born to be!
      </p>
      {levels.map(({ level, reward, session, cost, stars }) => (
        <div key={level} className="level-option" data-level={level}>
          <h2>
            Level {level}{' '}
            {level === currentLevel && (
              <div className="badge">
                Current Level <i style={{ marginLeft: '5px', color: '#bda003' }} className="fas fa-bolt"></i>
              </div>
            )}
          </h2>
          <img src={`/upgrade/level${level}.png`} alt={`Level ${level}`} />
          <p>
            Earn{' '}
            <span style={{ color: '#48e4fa', fontWeight: 'bold' }}>{reward}</span> SFT Per Hour
            <br />
            Check in every <span style={{ color: 'gold', fontWeight: 'bold' }}>{session}</span> to continue mining.
          </p>
          <h3>{cost}</h3>
          <button
            onClick={() => triggerInvoice(level, stars)}
            disabled={level <= currentLevel || level !== currentLevel + 1 || invoiceLoading}
          >
            {level === currentLevel ? 'Unlocked' : 'Upgrade to Level ' + level}
          </button>
        </div>
      ))}
    </div>
  </>
)}

      {alertMessage && <CustomAlert message={alertMessage} onClose={closeAlert} />}
    </div>
  );
}
