import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import './App.css';
import Tasks from './Tasks';
import Miner from './Miner';
import Friends from './Friends';
import Softie from './Softie';
import More from './More';
import Home from './Home';
import { SoundWaveLoader } from './SoundWaveLoader';
import Upgrade from './Upgrade';
import Notification from './Notification';
import ConnectWallet from './ConnectWallet';
import Withdraw from './Withdraw';
import Deposit from './Deposit';
import SoftieLevels from './SoftieLevels';
import History from './History';
import CryptoMarket from './CryptoMarket';
import WheelOfFortune from './WheelOfFortune';
import Contest from './Contest';
import Profile from './Profile';
import Trybe from './Trybe';
import SoftieLeaders from './SoftieLeaders';

function App() {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const [timeRemaining, setTimeRemaining] = useState("");

  useEffect(() => {
    const targetDate = new Date("2024-12-20T00:00:00Z").getTime();

    const updateTimer = () => {
      const now = new Date().getTime();
      const distance = targetDate - now;

      if (distance <= 0) {
        setTimeRemaining("Countdown completed!");
        clearInterval(timerInterval);
        return;
      }

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setTimeRemaining(`${days}d ${hours}h ${minutes}m ${seconds}s`);
    };

    const timerInterval = setInterval(updateTimer, 1000);

    return () => clearInterval(timerInterval);
  }, []);

  React.useEffect(() => {
   var _mtm = window._mtm = window._mtm || [];
   _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
   var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
   g.async=true; g.src='https://cdn.matomo.cloud/softcoinmini.matomo.cloud/container_DMy5zCg1.js'; s.parentNode.insertBefore(g,s);
  }, [])

  useEffect(() => {
  try {
    if (!window.Telegram || !window.Telegram.WebApp) {
      console.error('Telegram WebApp is not defined');
      return;
    }

    const webApp = window.Telegram.WebApp;
    webApp.ready();  // Ensure the app is fully loaded

    // Set user data
    const userData = webApp.initDataUnsafe?.user;
    setUser(userData);

  } catch (error) {
    console.error('An error occurred during initialization:', error);
  }
}, []);

useEffect(() => {
  try {
    if (!window.Telegram || !window.Telegram.WebApp) {
      console.error('Telegram WebApp is not defined');
      return;
    }

    const webApp = window.Telegram.WebApp;
    webApp.ready();  // Ensure the app is fully loaded
    webApp.expand(); // Expand the Telegram browser window

  } catch (error) {
    console.error('An error occurred during initialization:', error);
  }
}, []);

useEffect(() => {
  try {
    if (!window.Telegram || !window.Telegram.WebApp) {
      console.error('Telegram WebApp is not defined');
      return;
    }

    const webApp = window.Telegram.WebApp;
    webApp.ready();  // Ensure the app is fully loaded

    // Set the color of the title bar
    webApp.setHeaderColor('#020010');

  } catch (error) {
    console.error('An error occurred during initialization:', error);
  }
}, []);

  const keepMining = () => {
        navigate('/home');
    };
  const goDeposit = () => {
        navigate('/deposit');
    };

 // useEffect(() => {
 // const timer = setTimeout(() => {
 //   navigate('/home');
//  }, 2000); // 2 seconds delay

  // Cleanup timer on component unmount
//  return () => clearTimeout(timer);
//}, [navigate]);


  return (
    <div className="plain-page">
      
    {/* <SoundWaveLoader /> */}

    <img src="/testnet.png" alt="Testnet" style={{ width: '100%', margin: '0 auto'}}/>
  <div style={{ width: '80%', margin: '0 auto' }}>
        {/* Countdown Timer */}
        <div style={{ textAlign: 'center', fontSize: '20px', marginTop: '10px', color: '#D3D3D3', fontWeight: 'bold' }}>
          {timeRemaining} <span style={{ fontSize: '20px', color: 'lightblue', marginLeft: '10px', color: 'gray'}}>To Go</span>
        </div>

        <button
          style={{ marginTop: '30px', background: '#ffffff00', border: 'solid 2px #D3D3D3', fontSize: '15px', padding: '10px'}}
          onClick={keepMining}
          className="submit-button"
        >
          Continue Mining
        </button>
        <button style={{ marginTop: '10px', fontSize: '15px', padding: '10px' }} onClick={goDeposit} className="submit-button">
          Make A Commitment
        </button>
      </div>
    </div>

  );
}

function AppWrapper() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
      <Route path="/tasks" element={<Tasks />} />
      <Route path="/friends" element={<Friends />} />
      <Route path="/softie" element={<Softie />} />
      <Route path="/more" element={<More />} />
      <Route path="/home" element={<Home />} />
      <Route path="/upgrade" element={<Upgrade />} />
      <Route path="/notification" element={<Notification />} />
      <Route path="/connect-wallet" element={<ConnectWallet />} />
      <Route path="/withdraw" element={<Withdraw />} />
      <Route path="/deposit" element={<Deposit />} />
      <Route path="/softie-levels" element={<SoftieLevels />} />
      <Route path="/history" element={<History />} />
      <Route path="/crypto-market" element={<CryptoMarket />} />
      <Route path="/wheel-of-fortune" element={<WheelOfFortune />} />
      <Route path="/contest" element={<Contest />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/miner" element={<Miner />} />
      <Route path="/trybe" element={<Trybe />} />
      <Route path="/softie-leaders" element={<SoftieLeaders />} />
      </Routes>
    </Router>
  );
}

export default AppWrapper;
