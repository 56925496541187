import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import the useNavigate hook
import './Withdraw.css'; 
import CustomAlert from './CustomAlert';
import { SoundWaveLoader } from './SoundWaveLoader';

export default function Withdraw() {
  const [balance, setBalance] = useState(0);  // Store user's balance
  const [amount, setAmount] = useState('');   // Input value for withdrawal amount
  const [walletAddress, setWalletAddress] = useState('');  // Wallet address fetched from database
  const [totalWithdrawal, setTotalWithdrawal] = useState(0); // Store user's total withdrawals
  const [alertMessage, setAlertMessage] = useState(null); // Manage alert state
  const [loading, setLoading] = useState(true);
  
  const closeAlert = () => {
    setAlertMessage(null); // Reset alertMessage to hide the alert
  };

  const showTelegramAlert = (message) => {
  const tg = window.Telegram.WebApp; // Access Telegram WebApp object
  if (tg && tg.showAlert) {
    tg.showAlert(message); // Use Telegram's showAlert method
  } else {
    console.error('Telegram WebApp is not available or showAlert is not supported');
  }
};

  const navigate = useNavigate();
  
  const goBack = () => {
    navigate('/softie'); // Navigate to the /deposit page
  };
  
  const historyPage = () => {
    navigate('/history'); // Navigate to the /deposit page
  };
  // Access Telegram WebApp SDK
const telegram = window.Telegram.WebApp;

// Retrieve username from Telegram, fallback to chatId if username is not available
const username = telegram?.initDataUnsafe?.user?.username || telegram?.initDataUnsafe?.user?.id.toString();

  const [method] = useState('BNB'); // Default withdrawal method

  // Redirect after a delay
  const redirectAfterDelay = (path, delay = 5000) => {
    setTimeout(() => {
      navigate(path);
    }, delay);
  };

useEffect(() => {
  try {
    if (!window.Telegram || !window.Telegram.WebApp) {
      console.error('Telegram WebApp is not defined');
      return;
    }

    const webApp = window.Telegram.WebApp;
    webApp.ready();  // Ensure the app is fully loaded

    // Set the color of the title bar
    webApp.setHeaderColor('#020010');

  } catch (error) {
    console.error('An error occurred during initialization:', error);
  }
}, []);

  // Fetch the wallet address, earning balance, and total withdrawal when the component mounts
  useEffect(() => {
  let activeRequests = 3; // Track the number of active requests
  

  const handleRequestComplete = () => {
    activeRequests -= 1; // Decrement the counter when a request finishes
    if (activeRequests === 0) {
      setLoading(false); // Set loading to false when all requests are done
    }
  };

  fetch(`https://softie.onrender.com/api/walletAddress/${username}`)
    .then((response) => response.json())
    .then((data) => {
      if (data.error || !data.walletAddress) {
        // If the wallet address is empty or there's an error, alert the user and redirect
        showTelegramAlert('Please connect your wallet.');
        redirectAfterDelay('/connect-wallet');
      } else {
        setWalletAddress(data.walletAddress);
      }
    })
    .catch((error) => {
      console.error('Error fetching wallet address:', error);
      showTelegramAlert('Error fetching wallet address.');
    })
    .finally(handleRequestComplete); // Call completion handler

  fetch(`https://softie.onrender.com/api/users/${username}/earningBalance`)
    .then((response) => response.json())
    .then((data) => {
      if (data.error) {
        showTelegramAlert('Error fetching earning balance.');
      } else {
        setBalance(Math.floor(data.earningBalance)); // Round balance to nearest integer
      }
    })
    .catch((error) => {
      console.error('Error fetching earning balance:', error);
      showTelegramAlert('Error fetching earning balance.');
    })
    .finally(handleRequestComplete); // Call completion handler

  fetch(`https://softie.onrender.com/api/users/${username}/withdrawals`)
    .then((response) => response.json())
    .then((data) => {
      if (data.error) {
        showTelegramAlert('Error fetching withdrawal data.');
      } else {
        setTotalWithdrawal(data.totalWithdrawal || 0); // Set the total withdrawal
      }
    })
    .catch((error) => {
      console.error('Error fetching withdrawal data:', error);
      showTelegramAlert('Error fetching withdrawal data.');
    })
    .finally(handleRequestComplete); // Call completion handler
}, [username]);


  // Handle "Use Max" button click
  const handleUseMax = () => {
    setAmount(balance);
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate input fields
    if (amount < 10) {
      showTelegramAlert('Minimum Withdrawal is 10 USD.');
      return;
    }

    if (!walletAddress.trim()) {
      showTelegramAlert('Wallet address is not available.');
      return;
    }

    // Display loading alert
    showTelegramAlert('Processing your request...', 'loading');

    // Fetch both earning balance and commitment balance
    Promise.all([
      fetch(`https://softie.onrender.com/api/users/${username}/earningBalance`).then(response => response.json()),
      fetch(`https://softie.onrender.com/api/users/${username}/commitmentBalance`).then(response => response.json())
    ])
    .then(([earningData, commitmentData]) => {
      if (earningData.error || commitmentData.error) {
        showTelegramAlert('Error fetching balances.');
        return;
      }

      if (amount > earningData.earningBalance) {
        showTelegramAlert('The amount exceeds your earning balance.');
      } else if (commitmentData.commitmentBalance <= 0) {
        showTelegramAlert('Your commitment balance must be greater than 0 to withdraw.');
      } else {
        // Submit withdrawal request
        fetch('https://softie.onrender.com/api/withdraw', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ username, amount, method, walletAddress })
        })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            showTelegramAlert('Withdrawal request submitted successfully.', 'success');
            redirectAfterDelay('/softie'); // Redirect after 5 seconds
          } else {
            showTelegramAlert('Withdrawal request submitted successfully.', 'success');
            redirectAfterDelay('/softie');
          }
        })
        .catch(error => {
          console.error('Error submitting withdrawal request:', error);
          showTelegramAlert('Failed to submit withdrawal request.');
        });
      }
    })
    .catch(error => {
      console.error('Error fetching balances:', error);
      showTelegramAlert('Error fetching balances.');
    });
  };

  const showCustomAlert = (message) => {
    setAlertMessage(message); // Set the custom alert message to trigger the alert
  };

  return (
    <div className="withdraw app">
    {loading ? ( // Display "Loading" while loading is true
        <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <SoundWaveLoader /> 
        </div>
      ) : (
      <>
      <h2 style={{ marginTop: '33px', fontSize: '30px'}}>Cash Out</h2>
      <a onClick={goBack}>
        <i className="fas fa-arrow-left" style={{ color: 'white', position: 'fixed', left: '18px', top: '40px', zIndex: '500', fontSize: '20px' }}></i>
      </a>

      <h3 className="wallet-b">Balance: <span>${balance.toFixed(0)}</span></h3>

      <p>Enter Amount (USD)</p>
      <input
        type="number"
        placeholder="0"
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
        style={{
          width: '90%', padding: '0', height: '150px', textAlign: 'center',
          fontSize: '60px', margin: '5px auto', background: 'none', color: 'white', marginTop: '5px', border: 'none', outline: 'none'
        }}
      />

      <button
        style={{ fontSize: '10px', width: '20%', padding: '2px', margin: '5px auto' }}
        className="submit-button"
        onClick={handleUseMax}
        type="button"
      >
        Use Max
      </button>

      <button
        style={{ width: '50%', margin: '30px auto' }}
        className="submit-button"
        onClick={handleSubmit}
        type="submit"
      >
        Withdraw
      </button>

      <p style={{ textAlign: 'center' }} className="note">NOTE:</p>
      <p className="note">10 percent of the withdrawn amount will be deducted for withdrawal processing.</p>
      <p className="note">Transaction will be carried out strictly with BNB (BEP-20), ensure the wallet address you connected is accurate.</p>

      <h4 className="cashed">Cashed Out: <span>${totalWithdrawal.toFixed(0)}</span></h4> {/* Updated to display totalWithdrawal */}
      <a style={{ textDecoration: 'none', width: '60%', margin: '30px auto'}} className="history" onClick={historyPage}>View Transaction History</a>
          </>
        )}
      {alertMessage && <CustomAlert message={alertMessage} onClose={closeAlert} />}
    </div>
  );
}
