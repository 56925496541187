import { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import './App.css';
import ticket from './game/ticket.png';
import BottomNav from './BottomNav';
import { SoundWaveLoader } from './SoundWaveLoader';
import Modal from './Modal';


const slides = [
  { image: '/new/testnetad.png', link: '/deposit' },
  { image: '/new/decemberad.png', link: '/contest' },
  { image: '/new/upgradead.png', link: '/upgrade' },
];

export default function Home() {
  const [activeItem, setActiveItem] = useState('home');
  const [loading, setLoading] = useState(true);
  const [username, setUsername] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [coinBalance, setCoinBalance] = useState(0);
  const [earningBalance, setEarningBalance] = useState(0);
  const [currentLevel, setCurrentLevel] = useState(1);
  const [miningSessionCount, setMiningSessionCount] = useState(0);
  const [spinTickets, setSpinTickets] = useState(0);
  const [statusMessage, setStatusMessage] = useState("Click the power button 👇 to start");
  const [timer, setTimer] = useState("00:00:00");
  const [timerInterval, setTimerInterval] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [greeting, setGreeting] = useState('Hello');
  const [dailyProfit, setDailyProfit] = useState(0);
  const [error, setError] = useState('');
  const [dynamicCoinCount, setDynamicCoinCount] = useState(0);
  const [walletAddress, setWalletAddress] = useState('');  // Add walletAddress state
  const [showMiningAlert, setShowMiningAlert] = useState(false); // For controlling modal visibility
  const [miningInProgress, setMiningInProgress] = useState(false); // Add mining status state
  const [showModal, setShowModal] = useState(false);
  const [showLevelModal, setShowLevelModal] = useState(false);
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const navigate = useNavigate();
  
  const [isDragging, setIsDragging] = useState(false);
  const [startPosition, setStartPosition] = useState(0);
  const [translateX, setTranslateX] = useState(0);
  const intervalRef = useRef(null);

  const slideCount = slides.length;

  const handleModalButtonClick = () => {
    setShowModal(false); // Hide the modal
    navigate('/softie-levels'); // Navigate to the desired page
  };

  const handleLevelButtonClick = () => {
    setShowLevelModal(false); // Hide the modal
    navigate('/upgrade'); // Navigate to the desired page
  };

  const closeModal = () => {
    setShowModal(false); // Close the modal
  };

  const closeWelcomeModal = () => {
    setShowWelcomeModal(false); // Close the modal
  };
  
  const closeLevelModal = () => {
    setShowLevelModal(false); // Close the modal
  };


  const startDragging = (position) => {
    setIsDragging(true);
    setStartPosition(position);
    clearInterval(intervalRef.current); // Pause automatic slide
  };

  const stopDragging = () => {
    setIsDragging(false);
    if (translateX > 50) {
      // Slide back
      prevSlide();
    } else if (translateX < -50) {
      // Slide forward
      nextSlide();
    } else {
      // Reset position
      setTranslateX(0);
    }
    autoSlide(); // Resume automatic slide
  };

  const handleDragging = (position) => {
    if (isDragging) {
      setTranslateX(position - startPosition);
    }
  };

  const nextSlide = useCallback(() => {
    setCurrentSlide((prev) => (prev + 1) % slideCount);
    setTranslateX(0); // Reset after slide
  }, [slideCount]);

  const prevSlide = useCallback(() => {
    setCurrentSlide((prev) => (prev - 1 + slideCount) % slideCount);
    setTranslateX(0); // Reset after slide
  }, [slideCount]);

  const autoSlide = useCallback(() => {
    intervalRef.current = setInterval(() => {
      nextSlide();
    }, 10000); // Change every 10 seconds
  }, [nextSlide]);

  useEffect(() => {
    autoSlide();
    return () => clearInterval(intervalRef.current); // Clean up on unmount
  }, [autoSlide]);
  
  const handleSlideClick = () => {
    const slide = slides[currentSlide];
    if (slide.link.startsWith('http')) {
      // External link, open in a new tab
      window.open(slide.link, '_blank', 'noopener,noreferrer');
    } else {
      // Internal link, navigate within the app
      navigate(slide.link);
    }
  };

  
  const profilePage = () => {
    navigate('/profile'); // Navigate back to the /softie page
  };

  const wheelsPage = () => {
    navigate('/wheel-of-fortune'); // Navigate back to the /softie page
  };

const notificationPage = () => {
    navigate('/notification'); // Navigate back to the /softie page
  };

const upgradePage = () => {
    navigate('/upgrade'); // Navigate back to the /softie page
  };

const withdrawPage = () => {
    navigate('/withdraw'); // Navigate back to the /softie page
  };


  const rewardIntervals = useMemo(() => [2 * 60 * 60 * 1000, 3 * 60 * 60 * 1000, 4 * 60 * 60 * 1000, 5 * 60 * 60 * 1000, 6 * 60 * 60 * 1000], []);
  
  const rewardIntervalsInHours = useMemo(() => [2, 3, 4, 5, 6], []);
  
  const rewards = useMemo(() => [5000, 10000, 20000, 40000, 80000], []);
  
  const [unreadCount, setUnreadCount] = useState(0);
  
  const handleClick = () => {
    if (!walletAddress) {
      navigate('/connect-wallet');
    }
  };

  useEffect(() => {
  const checkModalDisplay = () => {
    const firstVisit = localStorage.getItem('firstVisit');
    const lastShownDate = localStorage.getItem('lastModalShownDate');
    const today = new Date().toISOString().split('T')[0]; // Format: YYYY-MM-DD

    if (!firstVisit) {
      // If it's the user's first visit ever
      setShowWelcomeModal(true);
      localStorage.setItem('firstVisit', 'true'); // Mark first visit
      localStorage.setItem('lastModalShownDate', today); // Save today's date
    } else if (lastShownDate !== today) {
      // If not the first visit, but the modal hasn't been shown today
      setShowModal(true);
      localStorage.setItem('lastModalShownDate', today); // Save today's date
    }
  };

  checkModalDisplay();
}, []);


  useEffect(() => {
  try {
    if (!window.Telegram || !window.Telegram.WebApp) {
      console.error('Telegram WebApp is not defined');
      return;
    }

    const webApp = window.Telegram.WebApp;
    webApp.ready();  // Ensure the app is fully loaded

    // Set the color of the title bar
    webApp.setHeaderColor('#020010');

  } catch (error) {
    console.error('An error occurred during initialization:', error);
  }
}, []);
  
  useEffect(() => {
    try {
      if (!window.Telegram || !window.Telegram.WebApp) {
        console.error('Telegram WebApp is not defined');
        return;
      }

      const webApp = window.Telegram.WebApp;
      webApp.ready();

      // Access user data
      const userData = webApp.initDataUnsafe.user;
      if (userData) {
        setProfilePhoto(userData.photo_url || null);
      } else {
        console.error('User data is not available');
      }
    } catch (error) {
      console.error('An error occurred during initialization:', error);
    }
  }, []);

const fetchWalletAddress = useCallback(async (username) => {
  try {
    const response = await fetch(`https://softie.onrender.com/api/walletAddress/${username}`);
    const data = await response.json();
    setWalletAddress(data.walletAddress || '');
  } catch (error) {
    console.error('Error fetching wallet address:', error);
  }
}, []); // Dependencies array can be adjusted based on required dependencies.

const checkMiningStatus = useCallback(async (username) => {
  try {
    const response = await fetch(`https://softie.onrender.com/api/miningStatus/${username}`);
    const data = await response.json();
    setMiningInProgress(data.isMining);
  } catch (error) {
    console.error('Error fetching mining status:', error);
  } finally {
    setLoading(false);
  }
}, []);

  useEffect(() => {
    const fetchGreeting = () => {
      const currentHour = new Date().getHours();
      if (currentHour < 12) {
        setGreeting("Good morning");
      } else if (currentHour < 18) {
        setGreeting("Good afternoon");
      } else {
        setGreeting("Good evening");
      }
    };

    fetchGreeting();
  }, []);
  
  useEffect(() => {
    async function fetchNotifications() {
      try {
        const response = await fetch(`https://softie.onrender.com/api/notifications/${username}`);
        let notificationsData = await response.json();

        // Sort notifications by date (most recent first)
        notificationsData.sort((a, b) => new Date(b.date) - new Date(a.date));

        let unread = 0;
        notificationsData.forEach(notification => {
          if (!notification.read) unread++;
        });

        setUnreadCount(unread);
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    }

    fetchNotifications();
  }, [username]);
  
  useEffect(() => {
    if (username) {
      const fetchEarningBalance = async () => {
        try {
          const response = await fetch(`https://softie.onrender.com/api/users/${username}/earningBalance`);
          if (!response.ok) {
            throw new Error('Failed to fetch earning balance');
          }
          const data = await response.json();
          setEarningBalance(data.earningBalance.toLocaleString(undefined, { maximumFractionDigits: 2 }));
        } catch (error) {
          console.error('Error fetching earning balance:', error);
        }
      };

      fetchEarningBalance();
    }
  }, [username]);
  
  useEffect(() => {
        fetch(`https://softie.onrender.com/api/users/${username}/commitmentBalance`)
            .then(response => response.json())
            .then(data => {
                if (data.error) {
                    setError('Error fetching commitment balance');
                } else {
                    const balance = data.commitmentBalance;

                    let profit = 0;
                  

                    if (balance >= 5 && balance <= 30) {
                        profit = balance * 0.015;
                        
                    } else if (balance >= 31 && balance <= 100) {
                        profit = balance * 0.02;
                        
                    } else if (balance >= 101 && balance <= 500) {
                        profit = balance * 0.025;
                        
                    } else if (balance >= 501 && balance <= 2000) {
                        profit = balance * 0.03;
                        
                    } else if (balance >= 2001 && balance <= 5000) {
                        profit = balance * 0.035;
                        
                    } else if (balance >= 5001) {
                        profit = balance * 0.04;
                        
                    }

                    setDailyProfit(profit.toFixed(2));

                }
            })
            .catch(() => {
                setError('Error fetching commitment balance');
            });
    }, [username]);

  const startMining = async () => {
  setLoading(true); // Show loader
  try {
    const response = await fetch('https://softie.onrender.com/api/startMiningMini', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username }),
    });
    const data = await response.json();
    
    if (data.miningStartTime) {
      startTimer(new Date(data.miningStartTime).getTime(), data.level);
      setSpinTickets(data.spinTickets);
      setStatusMessage("Mining in progress...");

      // Check if the mining alert should be shown
      const lastShown = localStorage.getItem('miningAlertLastShown');
      const now = new Date().getTime();
      const oneDay = 24 * 60 * 60 * 1000;

      if (!lastShown || now - lastShown > oneDay) {
        setShowMiningAlert(true);
        localStorage.setItem('miningAlertLastShown', now.toString());
      } else {
        setShowMiningAlert(false);
      }
    } else {
      console.error(data.message || "Error starting mining");
    }
  } catch (error) {
    console.error("Failed to start mining", error);
  } finally {
    setLoading(false); // Hide loader after operation
  }
};

  const handleSpinNow = () => {
    setShowMiningAlert(false);
    navigate('/wheel-of-fortune'); // Navigate to the spinning wheel
  };

  const handleLater = () => {
    setShowMiningAlert(false); // Close the alert
  };

  const startTimer = useCallback((startTime, level) => {
  const endTime = startTime + rewardIntervals[level - 1];
  const totalReward = rewards[level - 1];

  const updateTimer = () => {
    const remainingTime = endTime - Date.now();
    if (remainingTime <= 0) {
      clearInterval(timerInterval);
      setTimer("00:00:00");
      setStatusMessage("Click the power button 👇 to start");
      setDynamicCoinCount(0); // Reset the dynamic count
    } else {
      const hours = Math.floor((remainingTime / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((remainingTime / (1000 * 60)) % 60);
      const seconds = Math.floor((remainingTime / 1000) % 60);
      setTimer(`${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`);

      // Calculate the newly mined coins so far
      const elapsedTime = Date.now() - startTime;
      const minedCoins = totalReward * (elapsedTime / rewardIntervals[level - 1]);

      // Update only the dynamic coin count
      setDynamicCoinCount(minedCoins);
    }
  };

  updateTimer();
  const interval = setInterval(updateTimer, 1000); // Update every second
  setTimerInterval(interval);
}, []);

const updateMiningStatus = useCallback(async (username) => {
  try {
    const response = await fetch('https://softie.onrender.com/api/miningStatus', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username }),
    });

    const data = await response.json();

    if (data.miningComplete) {
      setCoinBalance(data.coinBalance);
      setCurrentLevel(data.level);
      setMiningSessionCount(data.miningSessionCount);
      setSpinTickets(data.spinTickets);
      setStatusMessage("Click the power button 👇 to start");
    } else if (data.miningStartTime) {
      startTimer(new Date(data.miningStartTime).getTime(), data.level);
      setCoinBalance(data.coinBalance);
      setCurrentLevel(data.level);
      setMiningSessionCount(data.miningSessionCount);
      setSpinTickets(data.spinTickets);
      setStatusMessage("Mining in progress...");
    } else {
      setStatusMessage("Mining not started");
    }

    // Show the level modal only on the user's second visit in 1 day
    if (data.level < 5) {
      const lastVisitDate = localStorage.getItem('levelModalDate');
      const today = new Date().toDateString(); // Get today's date as a string
      const visitCount = parseInt(localStorage.getItem('levelModalVisitCount') || '0', 10);

      if (lastVisitDate !== today) {
        // Reset the visit count for a new day
        localStorage.setItem('levelModalDate', today);
        localStorage.setItem('levelModalVisitCount', '1'); // First visit today
        setShowLevelModal(false); // Do not show on the first visit
      } else if (visitCount === 1) {
        // Second visit today
        setShowLevelModal(true);
        localStorage.setItem('levelModalVisitCount', '2'); // Update visit count
      } else {
        // For visits beyond the second one, do not show the modal
        setShowLevelModal(false);
      }
    } else {
      setShowLevelModal(false);
    }
  } catch (error) {
    console.error("Error updating mining status:", error);
    setStatusMessage("An error occurred while updating mining status.");
  } finally {
    setLoading(false); // Hide loader after operation
  }
}, []);

useEffect(() => {
  const fetchTelegramUser = () => {
    try {
      if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.initDataUnsafe) {
        const telegramUserData = window.Telegram.WebApp.initDataUnsafe.user;
        
        if (telegramUserData.username) {
          
          setUsername(telegramUserData.username); // Keep the existing username logic
          setFirstName(telegramUserData.first_name || telegramUserData.username); // Use first name if available
          updateMiningStatus(telegramUserData.username);
          fetchWalletAddress(telegramUserData.username);
          
        } else if (telegramUserData.id) { 
          
          setUsername(telegramUserData.id.toString());
          setFirstName(telegramUserData.first_name || telegramUserData.id.toString()); // Use first name or fallback to id
          updateMiningStatus(telegramUserData.id.toString());
          fetchWalletAddress(telegramUserData.id.toString());
          
        } else {
          console.error('No username or chatId available from Telegram.');
        }
      } else {
        console.error('Telegram WebApp is not initialized.');
      }
    } catch (error) {
      console.error('Error fetching user data from Telegram:', error);
    }
  };

  fetchTelegramUser();
}, [updateMiningStatus, fetchWalletAddress, checkMiningStatus]);

  return (
    <div className="app">

          <header className="top-bar">
        <div className="greeting">
        
          <p style={{ textAlign: 'left', marginBottom: '', fontSize: '8px', color: '#D3D3D3' }}>{greeting},<br/><span style={{ textAlign: 'left', fontSize: '18px', fontWeight: 'bold', color: '#D3D3D3' }}>{firstName}!</span></p>
        </div>
        <div className="icons">
          <div onClick={upgradePage} className="icon-container level-div">
            <i
        className={`fas fa-bolt ${
          statusMessage === "Mining in progress..." ? "blinking-icon" : ""
        }`}
      ></i>
            <div className="level-text">Level</div>
            <div className="level-number">{currentLevel}</div>
            <i
        className="fas fa-chevron-right"
      ></i>
          </div>
          <div onClick={profilePage} className="icon-container">
          {profilePhoto ? (
          <img
            src={profilePhoto}
            alt="Profile"
            style={{
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              objectFit: 'cover',
            }}
          />
        ) : (
          
            <i style={{ color: '#D3D3D3' }} className="fas fa-user-circle"></i>
          
        )}
          </div>
          
          <div onClick={notificationPage} className="icon-container bell">
            <i className="fas fa-bell"></i>
             {unreadCount > 0 && (
              <span className="icon-badge">{unreadCount}</span>
            )}
          </div>

        </div>
      </header>
      
      <div className="app-body">
  <div className="slideshow">
    <div
      className="slide-container"
      style={{
        transform: `translateX(calc(-${currentSlide * 100}% + ${translateX}px))`,
        transition: isDragging ? 'none' : 'transform 0.5s ease-in-out',
      }}
      onMouseDown={(e) => startDragging(e.clientX)}
      onMouseMove={(e) => handleDragging(e.clientX)}
      onMouseUp={stopDragging}
      onTouchStart={(e) => startDragging(e.touches[0].clientX)}
      onTouchMove={(e) => handleDragging(e.touches[0].clientX)}
      onTouchEnd={stopDragging}
    >
      {slides.map((slide, index) => (
        <div key={index} className="slide">
          <div style={{ margin: '0 auto', width: '91%' }} onClick={handleSlideClick}>
            <img src={slide.image} alt={`Slide ${index + 1}`} />
          </div>
        </div>
      ))}
    </div>

    {/* Dots Navigation */}
    <div className="dots-container">
      {slides.map((_, index) => (
        <span
          key={index}
          className={`dot ${index === currentSlide ? 'active' : ''}`}
          onClick={() => setCurrentSlide(index)}
        ></span>
      ))}
    </div>
  </div>

      <div className="vector">
        <div className="balance-box">
        <div className="balance-item">
          <img src="/menu/home-neon.png" alt="Coin Logo" className="balance-logo coin-logo" />
          <span className="balance-text coin">{coinBalance.toLocaleString(undefined, { maximumFractionDigits: 0 })}<br/><span style={{ color: '#48e4fa', fontSize: '12px'}}>SFT</span></span>
        </div>
        <div className="balance-item cash">
          <img style={{ marginLeft: '10px'}} src="/menu/softie-neon.png" alt="Earning Logo" className="balance-logo" />
          <span className="balance-text">{earningBalance !== null ? earningBalance : "Loading..."}<br/><span style={{ color: '#48e4fa', fontSize: '12px'}}>USD</span></span>
        </div>
      </div>
      <div className="wallet">
     <button 
      className="connect" 
      onClick={handleClick} 
      disabled={!!walletAddress}  // Disable if walletAddress is not empty
    >
      {walletAddress ? '🛡 Wallet Connected️!' : 'Connect Wallet 🚀'}  
    </button>
        
        <div onClick={withdrawPage} className="cashout"><span>Withdraw</span>
        <i className="fas fa-chevron-right" style={{ color: '#020010', marginLeft: '50px' }}></i>
        </div>
      </div>
      </div>

      {/* Coin Balance */}
      <div className="coin-balance">
      <span className="figure">+{dynamicCoinCount.toLocaleString(undefined, { maximumFractionDigits: 0 })}</span><span>{timer}</span>
  </div> 
  <div className="status-message"><p id="status-message">{statusMessage}</p></div>

{loading ? (
      <div style={{ marginLeft: '10px', padding: '75px'}}>
        <SoundWaveLoader /> 
      </div>
      ) : (
        <>
          {miningInProgress ? (
          <div className="mango">
            <button
  id="mine-btn"
  onClick={startMining}
  disabled={statusMessage === "Mining in progress..."}
  className={statusMessage === "Mining complete!" ? "breathing" : statusMessage === "Mining in progress..." ? "circulate" : ""}
>
  
</button>
</div>
          ) : (
          <div className="mango">
            <button
  id="mine-btn"
  onClick={startMining}
  disabled={statusMessage === "Mining in progress..."}
  className={statusMessage === "Mining complete!" ? "breathing" : statusMessage === "Mining in progress..." ? "circulate" : ""}
>
 
</button>
</div>
          )}
        </>
      )}

<div style={{ marginBottom: '10px'}} className="levels">
  <p>Daily Income: <span>${dailyProfit}</span></p>
  <p>Sessions Completed: <span>{miningSessionCount}</span></p>
</div>

<div className="levels">
  <div className="ticket-box" ><img className="ticket" src={ticket} alt="ticket"/><p>{spinTickets}</p></div>   
  <button onClick={wheelsPage}  style={{ fontSize: '1em', width: '50%', padding: '10px', background: 'blue', margin: '0'}} className="submit-button">Spin</button>
</div>

<p style={{ display: 'none'}}>{error}</p>
</div>
      {/* Conditional Alert Modals */}
      


  <Modal 
    isOpen={showModal}
    onClose={closeModal}
    imageSrc="/deposit.png"
    buttonText="Make A Deposit"
    onButtonClick={handleModalButtonClick}
  />

  <Modal 
    isOpen={showMiningAlert}
    onClose={handleLater}
    imageSrc="/spin.png"
    buttonText="Spin Now"
    onButtonClick={handleSpinNow}
  />

   <Modal 
    isOpen={showLevelModal}
    onClose={closeLevelModal}
    imageSrc="/levelup.png"
    buttonText="Upgrade Level"
    onButtonClick={handleLevelButtonClick}
  />
      <Modal 
    isOpen={showWelcomeModal}
    onClose={closeWelcomeModal}
    imageSrc="/welcome.png"
    buttonText="Start Mining"
    onButtonClick={closeWelcomeModal}
  />
     
      <BottomNav activeItem={activeItem} setActiveItem={setActiveItem} />
    </div>
  );
}
