import { useState, useEffect } from 'react';
import './Upgrade.css';
import CustomAlert from './CustomAlert';
import { useNavigate, Routes, Route } from 'react-router-dom';

export default function SoftieLevels() {
  const [currentLevel, setCurrentLevel] = useState(null);
  const [username, setUsername] = useState(localStorage.getItem('username'));
  const [alertMessage, setAlertMessage] = useState(null); // Manage alert state

  const closeAlert = () => {
    setAlertMessage(null); // Reset alertMessage to hide the alert
  };
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/deposit'); // Navigate to the /deposit page
  };
  
  const goBack = () => {
    navigate('/softie');
  };
  
  useEffect(() => {
  try {
    if (!window.Telegram || !window.Telegram.WebApp) {
      console.error('Telegram WebApp is not defined');
      return;
    }

    const webApp = window.Telegram.WebApp;
    webApp.ready();  // Ensure the app is fully loaded

    // Set the color of the title bar
    webApp.setHeaderColor('#020010');

  } catch (error) {
    console.error('An error occurred during initialization:', error);
  }
}, []);

  return (
    <div className="Upgrade app">
      <a onClick={goBack}>
        <i className="fas fa-arrow-left" style={{ color: 'white', position: 'fixed', left: '20px', top: '40px', zIndex: '500', fontSize: '20px' }}></i>
      </a>
      <div id="upgrade-options">
        <p id="text">Joining Softie is the only way you can earn instantly withdrawable income every day, plus additional benefits at TGE.</p>
        <p id="text">Your daily earnings can be as high as $500 (even more), depends on the level you are on, which is determined by the amount you commited.</p>
          <div className="level-option base" >
            <h2> Rising Softie {currentLevel && <div className="badge">Current Level <i style={{marginLeft: '5px', color: '#bda003'}} className="fas fa-bolt"></i></div>}
            </h2>
            <img style={{ background: 'linear-gradient(90deg, #036287, #013d55)'}} className="image" src="/images/1.png" />
            <p style={{ fontSize: '14px', margin: '10px 0px 0px 0px'}} >With Commitment Between:</p>
            <h3 style={{ color: 'white', fontSize: '25px', margin: '0px 5px 5px 5px'}} >$5 to $30</h3>
            <p>Earn Up To <span style={{ color: '#48e4fa', fontWeight: 'bold', fontSize: '30px'}}>$0.5</span> Daily<br/>At <span style={{ color: 'gold', fontWeight: 'bold'}}>1.5%</span> Daily Return</p>
            <button onClick={handleNavigate} className="rep">Make A Commitment</button>
          </div>
          <div className="level-option base" >
            <h2> Junior Softie {currentLevel && <div className="badge">Current Level <i style={{marginLeft: '5px', color: '#bda003'}} className="fas fa-bolt"></i></div>}
            </h2>
            <img style={{ background: 'linear-gradient(90deg, #1edd4e, #0b7225)'}} className="image" src="/images/2.png" />
            <p style={{ fontSize: '14px', margin: '10px 0px 0px 0px'}} >With Commitment Between:</p>
            <h3 style={{ color: 'white', fontSize: '25px', margin: '0px 5px 5px 5px'}} >$31 to $100</h3>
            <p>Earn Up To <span style={{ color: '#48e4fa', fontWeight: 'bold', fontSize: '30px'}}>$2</span> Daily<br/>At <span style={{ color: 'gold', fontWeight: 'bold'}}>2%</span> Daily Return</p>
            <button onClick={handleNavigate} className="rep">Make A Commitment</button>
          </div>
          <div className="level-option base" >
            <h2> Pro Softie {currentLevel && <div className="badge">Current Level <i style={{marginLeft: '5px', color: '#bda003'}} className="fas fa-bolt"></i></div>}
            </h2>
            <img style={{ background: 'linear-gradient(90deg, #f203b7, #7501b0)'}} className="image" src="/images/3.png" />
            <p style={{ fontSize: '14px', margin: '10px 0px 0px 0px'}} >With Commitment Between:</p>
            <h3 style={{ color: 'white', fontSize: '25px', margin: '0px 5px 5px 5px'}} >$101 to $500</h3>
            <p>Earn Up To <span style={{ color: '#48e4fa', fontWeight: 'bold', fontSize: '30px'}}>$12.5</span> Daily<br/>At <span style={{ color: 'gold', fontWeight: 'bold'}}>2.5%</span> Daily Return</p>
            <button onClick={handleNavigate} className="rep">Make A Commitment</button>
          </div>
          <div className="level-option base" >
            <h2> Expert Softie {currentLevel && <div className="badge">Current Level <i style={{marginLeft: '5px', color: '#bda003'}} className="fas fa-bolt"></i></div>}
            </h2>
            <img style={{ background: 'linear-gradient(90deg, #bd8738, #604319)'}} className="image" src="/images/4.png" />
            <p style={{ fontSize: '14px', margin: '10px 0px 0px 0px'}} >With Commitment Between:</p>
            <h3 style={{ color: 'white', fontSize: '25px', margin: '0px 5px 5px 5px'}} >$501 to $2000</h3>
            <p>Earn Up To <span style={{ color: '#48e4fa', fontWeight: 'bold', fontSize: '30px'}}>$60</span> Daily<br/>At <span style={{ color: 'gold', fontWeight: 'bold'}}>3%</span> Daily Return</p>
            <button onClick={handleNavigate} className="rep">Make A Commitment</button>
          </div>
          <div className="level-option base" >
            <h2> Master Softie {currentLevel && <div className="badge">Current Level <i style={{marginLeft: '5px', color: '#bda003'}} className="fas fa-bolt"></i></div>}
            </h2>
            <img style={{ background: 'linear-gradient(90deg, #8a8787, #4b4848)'}} className="image" src="/images/5.png" />
            <p style={{ fontSize: '14px', margin: '10px 0px 0px 0px'}} >With Commitment Between:</p>
            <h3 style={{ color: 'white', fontSize: '25px', margin: '0px 5px 5px 5px'}} >$2001 to $5000</h3>
            <p>Earn Up To <span style={{ color: '#48e4fa', fontWeight: 'bold', fontSize: '30px'}}>$175</span> Daily<br/>At <span style={{ color: 'gold', fontWeight: 'bold'}}>3.5%</span> Daily Return</p>
            <button onClick={handleNavigate} className="rep">Make A Commitment</button>
          </div>
          <div className="level-option base" >
            <h2> Legend Softie {currentLevel && <div className="badge">Current Level <i style={{marginLeft: '5px', color: '#bda003'}} className="fas fa-bolt"></i></div>}
            </h2>
            <img style={{ background: 'linear-gradient(90deg, #c69d01, #775e01)'}} className="image" src="/images/6.png" />
            <p style={{ fontSize: '14px', margin: '10px 0px 0px 0px'}} >With Commitment Of:</p>
            <h3 style={{ color: 'white', fontSize: '25px', margin: '0px 5px 0px 5px'}} >$5001</h3>
             <p style={{ fontSize: '14px', margin: '0px 0px 10px 0px'}} >And Above</p>
            <p>Your daily earnings at this level is <span style={{ color: '#48e4fa', fontWeight: 'bold', fontSize: '30px'}}>unlimited</span>.<br/>At <span style={{ color: 'gold', fontWeight: 'bold'}}>4%</span> Daily Return</p>
            <button onClick={handleNavigate} className="rep">Make A Commitment</button>
          </div>
      </div>
{/* Custom Alert */}
    {alertMessage && <CustomAlert message={alertMessage} onClose={closeAlert} />}
    </div>
  );
}
